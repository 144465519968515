import React from "react"

import Layout from "../components/layout"


import Accord from "../components/accord"
import pic from "../images/iron.jpg"

const Fundament = () => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            ФУНДАМЕНТ
          </h2>
        </div>
      </div>

      <section className="mt-16">
        <div className="">
          <div className=" grid grid-cols-1 md:grid-cols-2">
            <img src={pic} alt={`Услуги`} />
            <div className="flex flex-col justify-center md:ml-8">
              <p
                className="text-white text-sm md:text-sm lg:text-sm xl:text-sm"
                style={{
                  fontFamily: `Montserrat`,
                }}
              >
                Высокий уровень ответственности, большой опыт работы нашей фирмы
                на строительном рынке и опытные бригады строителей с высшим
                профильным образованием позволяют нам предоставлять услуги по
                строительству фундаментов любого типа на самом высоком уровне. В
                своей практике мы используем только проверенных поставщиков
                составляющих любого типа оснований – бетон от ведущих
                производителей, арматура, винтовые сваи, гидроизоляцию,
                пиломатериал для опалубки и другие для того, чтобы будущая
                конструкция выдержала необходимую нагрузку.
              </p>
            </div>
          </div>
          <Accord />
        </div>
      </section>
    </Layout>
  )
}

export default Fundament
