import React from "react"
import { Button, Accordion, Card } from "react-bootstrap"

import buronabivn from "../images/buronabivnoj-fundament.jpg"
import lentoch from "../images/lentochnyi-fundament.jpg"
import blockfund from "../images/blockfundation.jpg"
import podval from "../images/fundament-s-podvalom.jpg"
import svayniy from "../images/svajnyj-fundament.jpg"
import plita from "../images/plitafoundation.jpg"

const Accord = () => {
  return (
    <Accordion>
      <Card>
        <div>
          <Card.Header style={{ background: `#000000` }}>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              style={{ textDecoration: `none`, color: `white` }}
            >
              Буронабивной фундамент
            </Accordion.Toggle>
          </Card.Header>
        </div>
        <div>
          {" "}
          <Accordion.Collapse eventKey="0">
            <Card.Body style={{ background: `#000000` }}>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="flex self-center">
                  <img src={buronabivn} alt={`Буронабивной фундамент`} />
                </div>
                <div className="ml-2 ">
                  <h4
                    className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                    style={{ fontFamily: `Montserrat` }}
                  >
                    Буронабивной фундамент
                  </h4>
                  <p
                    style={{ fontFamily: `Montserrat` }}
                    className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                  >
                    Рассмотрим более внимательно типовой буронабивной фундамент,
                    который используется в строительстве качественных домов на
                    вязких и слабых грунтах, отличающихся от прочих подвижностью
                    и прочими не всегда положительными характеристиками.
                    Буронабивной фундамент чаще всего применяется именно в
                    условиях сложных грунтов. Для обычных домов на хорошей
                    поверхности такой тип основания используется все чаще для
                    экономии средств. Буронабивной фундамент отличается высокой
                    прочностью, меньшими затратами и расходом бетона, а также
                    отлично подходит для любых строений.
                  </p>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Card>
      <Card>
        <Card.Header style={{ background: `#000000` }}>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="1"
            style={{ textDecoration: `none`, color: `white` }}
          >
            Ленточный фундамент
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body style={{ background: `#000000` }}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex self-center">
                <img src={lentoch} alt={`Ленточный фундамент`} />
              </div>
              <div className="ml-2 ">
                <h4
                  className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  Ленточный фундамент
                </h4>
                <p
                  style={{ fontFamily: `Montserrat` }}
                  className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                >
                  Популярный в наше время и наиболее часто используемый
                  ленточный фундамент имеет ряд неоспоримых преимуществ,
                  среди которых скорость строительства дома из кирпича, дерева
                  или каркаса и относительно низкая цена. Надежность здания и
                  строения установленного на основу дома определяется в первую
                  очередь материалами, которые используются для заливки
                  ленточного фундамента под ключ.
                </p>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header style={{ background: `#000000` }}>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="2"
            style={{ textDecoration: `none`, color: `white` }}
          >
            Фундамент из блоков
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body style={{ background: `#000000` }}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex self-center">
                <img src={blockfund} alt={`Фундамент из блоков`} />
              </div>
              <div className="ml-2 ">
                <h4
                  className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  Фундамент из блоков
                </h4>
                <p
                  style={{ fontFamily: `Montserrat` }}
                  className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                >
                  Компания ООО ФС-Технологии предлагает своим клиентам
                  изготовление и строительство фундамента из блоков Йошкар-Ола и
                  по Марий Эл. Отличное решение, которое применяется для
                  быстрого монтажа и обустройства основы дома готовыми
                  железобетонными конструкциями – ФБС. Скорость монтажа
                  обусловлена привлечением дополнительной строительной техники –
                  автокрана для установки блочного фундамента на участке.
                  Стоимость такой конструкции не отличается от монолитного
                  варианта исполнения.
                </p>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header style={{ background: `#000000` }}>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="3"
            style={{ textDecoration: `none`, color: `white` }}
          >
            Фундамент с подвалом
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body style={{ background: `#000000` }}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex self-center">
                <img src={podval} alt={`Фундамент с подвалом`} />
              </div>
              <div className="ml-2 ">
                <h4
                  className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  Фундамент с подвалом
                </h4>
                <p
                  style={{ fontFamily: `Montserrat` }}
                  className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                >
                  Наверняка каждый хотел бы построить дом с подвалом, погребом
                  или цокольным этажом? Да в чем же разница меду ними? На самом
                  деле, есть существенные отличия между помещениями этих типов.
                  Общее одно – они находятся чуть ниже уровня земли. Погреб
                  почти полностью скрыт под поверхностью земли, подвал – опущен
                  более, чем на половину высоты этажа в землю, а вот нормальное
                  жилое помещение должно заглубляться не более, чем на 1-1,2
                  метра от своей высоты.
                </p>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header style={{ background: `#000000` }}>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="4"
            style={{ textDecoration: `none`, color: `white` }}
          >
            Свайный фундамент
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body style={{ background: `#000000` }}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex self-center">
                <img src={svayniy} alt={`Свайный фундамент`} />
              </div>
              <div className="ml-2 ">
                <h4
                  className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  Свайный фундамент
                </h4>
                <p
                  style={{ fontFamily: `Montserrat` }}
                  className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                >
                  Популярный и широко применяемый свайный фундамент строится в
                  условиях сложных перепадов, болотистой местности и возвышенных
                  грунтовых вод. Данная конструкция сегодня применяется
                  повсеместно, особенно при строительстве легких домов из
                  каркаса или сип панелей. Если будущее строение будет стоять на
                  крутом склоне, применение простого, но надежного фундамента из
                  свай станет отличным вариантом для надежного строительства.
                </p>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header style={{ background: `#000000` }}>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="5"
            style={{ textDecoration: `none`, color: `white` }}
          >
            Фундамент плита
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body style={{ background: `#000000` }}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex self-center">
                <img src={plita} alt={`Фундамент плита`} />
              </div>
              <div className="ml-2 ">
                <h4
                  className="mt-2 ml-2 text-white text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{ fontFamily: `Montserrat` }}
                >
                  Фундамент плита
                </h4>
                <p
                  style={{ fontFamily: `Montserrat` }}
                  className="text-white ml-2 text-xs mt-4 md:text-sm lg:text-sm xl:text-sm"
                >
                  Монолитный фундамент плита строится на множестве различных
                  типах грунтов благодаря возможности использовать необходимые
                  дополнения. Среди явных преимуществ стоит отметить простое
                  обустройство подвала при достаточном заглублении самого
                  фундамента, а также наличие чернового пола для первого этажа.
                  По этой причине траты на обустройство монолитного фундамента
                  плиты непременно скажутся положительно на снижении стоимости
                  дальнейших работ.
                </p>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default Accord
